<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      Order: "Pedido",
      "Payment Method": "Forma de Pagamento",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "Aguardando pagamento",
      "Payment confirmed": "Pagamento confirmado",

      Address: "Endereço",
      "Send exactly value to make payment":
        "Envie exatamente o valor para efetuar o pagamento",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      Order: "Pedido",
      "Payment Method": "Forma de Pago",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "En espera de pago",
      "Payment confirmed": "Pago confirmado",

      Address: "Dirección",
      "Send exactly value to make payment":
        "Enviar exactamente el valor de hacer el pago",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        voucher: false,
        date: null,

        subtotal: "0.00",
        discount: "0.00",
        shipping: {
          method: "",
          value: "0.00",
          address: {
            zipcode: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
          },
        },
        payment: {
          methods: [],
          list: [],
        },

        total: "0.00",
        topay: "0.00",

        items: null,
        status: null,
      },

      voucher: {
        loading: false,
        alert: {
          type: "",
          message: "",
        },
        code: "",
      },

      document: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },

      file: null,

      proof: {
        add: false,
        loading: false,
        errored: false,
        status: false,
        list: null,
      },
    };
  },
  validations: {
    voucher: {
      code: { required },
    },
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get("store/orders/" + this.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;

              this.order.topay = parseFloat(response.data.order.total);
              response.data.order.payment.list.forEach((e) => {
                if (e.value > 0) {
                  this.order.topay -= parseFloat(e.value);
                }
              });

              this.loading = false;
            } else {
              this.empty = true;
              this.message = response.data.message;
            }
          })
          .catch((error) => {
            this.errored = error;
          });
      }
    },
    voucherSubmit() {
      if (this.voucher.code) {
        this.voucher.loading = true;

        api
          .post("wallet/order/voucher/" + this.order.id, {
            code: this.voucher.code,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.getOrder();
              this.voucher.loading = false;
            } else {
              this.voucher.alert.type = "alert-danger";
              this.voucher.alert.message = response.data.message;
              this.voucher.loading = false;
            }
          });
      }
    },
    getFile: function () {
      this.proof.loading = true;

      api
        .get("store/orders/file/" + this.order.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.proof.list = response.data.files;
          } else {
            this.proof.list = null;
          }
        })
        .catch((error) => {
          this.proof.errored = error;
          this.proof.loading = false;
        })
        .finally(() => {
          this.proof.loading = false;
        });
    },
    setFile() {
      this.document.loading = true;

      let formData = new FormData();

      formData.append("file", this.file);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(
          appConfig.apiUrl + "store/orders/file/" + this.order.id,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.getFile();
            this.$toast.success(response.data.message);
            this.document.status = false;
          } else {
            this.$toast.error(response.data.message);
            this.document.status = false;
          }
          this.document.loading = false;
        });
    },
    onFileSelected() {
      this.file = this.$refs.file[0].files[0];
    },
  },
  mounted() {
    this.getOrder();
    this.getFile();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-body">
            <div v-if="errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="empty">{{ t("No records found.") }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">
                  #{{ order.id }}<br />
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <div v-for="(td, index) in order.items" :key="index">
                  <h3 class="font-size-16 mt-4 mb-0 font-weight-normal">
                    {{ td.name }}
                  </h3>
                  <h3>{{ td.subtotal | currency }}</h3>
                </div>
              </div>
              <hr />
              <h3 class="font-size-16 mt-4 mb-2">{{ t("Status") }}</h3>
              <div v-for="(td, index) in order.status" :key="index">
                {{ td.date }} — {{ t(td.status) }}
              </div>
              <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
                <b-tab v-for="(pay, method) in order.payment.methods" :key="method">
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method === 'pix'">
                    <div class="text-center">
                      <img style="width: 250px" :src="'data:image/jpeg;base64,' + pay.base64" />
                      <p>Leia o qrcode acima ou</p>
                      <ol class="text-left">
                        <li>
                          Abra o app do seu banco ou seu app de pagamentos.
                        </li>
                        <li>Busque a opção de pagar com pix.</li>
                        <li>Copie e cole o seguinte código.</li>
                      </ol>

                      <div class="border rounded p-3 mb-3 text-left">
                        {{ pay.copypaste }}
                      </div>

                      <button class="btn btn-default" v-clipboard:copy="pay.copypaste" v-on:click="$toast.success('Copiado!')">
                        <i class="bx bx-copy font-size-18 align-middle"></i>
                        Copiar
                      </button>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
